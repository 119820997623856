import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function SoftwareGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Solutions logicielles INSTAR et logiciels tiers pour votre caméra INSTAR.
      </Typography>
      <Link to="/Software/Windows/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour Windows  </Typography>
      </Link>
      <Link to="/Software/macOS/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour macOS  </Typography>
      </Link>
      <Link to="/Software/Linux/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour Linux  </Typography>
      </Link>
      <Link to="/Software/Android/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour Android  </Typography>
      </Link>
      <Link to="/Software/iOS/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour iOS  </Typography>
      </Link>
      <Link to="/Software/Home_Automation/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour Automatisation de la maison  </Typography>
      </Link>
      <Link to="/Software/Other_Platforms/">
        <Typography variant="caption" color="textSecondary">║ Logiciels pour Autres plates-formes  </Typography>
      </Link>
    </div>
  );
}
