import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function PopularGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/">
        <Typography variant="caption" color="textSecondary">║ Tous les sujets du MQTT  </Typography>
      </Link>
      <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_vs_HTTP_API/">
        <Typography variant="caption" color="textSecondary">║ MQTT contre les commandements CGI  </Typography>
      </Link>
      <Link to="/1080p_Series_CGI_List/">
        <Typography variant="caption" color="textSecondary">║ Commandes CGI 1080p  </Typography>
      </Link>
      <Link to="/720p_Series_CGI_List/">
        <Typography variant="caption" color="textSecondary">║ Commandes CGI 720p  </Typography>
      </Link>
      <Link to="/Web_User_Interface/1080p_Series/">
        <Typography variant="caption" color="textSecondary">║ 1080p WebUI  </Typography>
      </Link>
      <Link to="/Web_User_Interface/720p_Series/">
        <Typography variant="caption" color="textSecondary">║ 720p WebUI  </Typography>
      </Link>
      <Link to="/Software/Linux/MotionEye/">
        <Typography variant="caption" color="textSecondary">║ MotionEye  </Typography>
      </Link>
      <Link to="/Quick_Installation/Alternative_IP_Scanner/">
        <Typography variant="caption" color="textSecondary">║ Outil de caméra IP  </Typography>
      </Link>
      <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/">
        <Typography variant="caption" color="textSecondary">║ OpenHAB INSTAR Camera Binding  </Typography>
      </Link>
      <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">
        <Typography variant="caption" color="textSecondary">║ Requêtes du serveur d&apos;alarme  </Typography>
      </Link>
      <Link to="/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/">
        <Typography variant="caption" color="textSecondary">║ WD MyCloud comme serveur FTP  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_and_MQTT/Projects/Live_Video/">
        <Typography variant="caption" color="textSecondary">║ Node-RED Live Video  </Typography>
      </Link>
      <Link to="/Software/Windows/P2P_Client/">
        <Typography variant="caption" color="textSecondary">║ P2P Client  </Typography>
      </Link>
      <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/">
        <Typography variant="caption" color="textSecondary">║ OpenHAB IP Camera Binding  </Typography>
      </Link>
      <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/">
        <Typography variant="caption" color="textSecondary">║ Shinobi Open Source CCTV  </Typography>
      </Link>
      <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/">
        <Typography variant="caption" color="textSecondary">║ ZoneMinder et Docker  </Typography>
      </Link>
      <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/">
        <Typography variant="caption" color="textSecondary">║ ioBroker et motionEye  </Typography>
      </Link>
      <Link to="/Software/Linux/Node-RED/">
        <Typography variant="caption" color="textSecondary">║ Node-RED  </Typography>
      </Link>
      <Link to="/Software/Linux/Home_Assistant/">
        <Typography variant="caption" color="textSecondary">║ Home Assistant  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_and_IFTTT/">
        <Typography variant="caption" color="textSecondary">║ Node-RED et IFTTT  </Typography>
      </Link>
      <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/">
        <Typography variant="caption" color="textSecondary">║ XiaoMi Home Zigbee2MQTT  </Typography>
      </Link>
    </div>
  );
}
