import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function DeveloperGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Contrôlez votre caméra via son API HTTP (CGI) ou MQTT et intégrez-la dans votre environnement smarthome.
      </Typography>
      <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
        <Typography variant="caption" color="textSecondary">║ VPN et AVM Fritzbox  </Typography>
      </Link>
      <Link to="/Advanced_User/Website_Integration/">
        <Typography variant="caption" color="textSecondary">║ Intégration du site web  </Typography>
      </Link>
      <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
        <Typography variant="caption" color="textSecondary">║ Youtube Videostreaming  </Typography>
      </Link>
      <Link to="/Advanced_User/CGI_Commands/">
        <Typography variant="caption" color="textSecondary">║ Commandes CGI  </Typography>
      </Link>
      <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
        <Typography variant="caption" color="textSecondary">║ INSTAR MQTT Broker  </Typography>
      </Link>
      <Link to="/Advanced_User/Homebridge_INSTAR_MQTT_to_Homekit/">
        <Typography variant="caption" color="textSecondary">║ Homebridge INSTAR MQTT  </Typography>
      </Link>
      <Link to="/Advanced_User/FHEM_on_a_Raspberry_Pi/">
        <Typography variant="caption" color="textSecondary">║ FHEM sur un Raspberry Pi  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_and_MQTT/">
        <Typography variant="caption" color="textSecondary">║ Node-RED et MQTT  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_Dashboard_Live_Video/">
        <Typography variant="caption" color="textSecondary">║ Node-RED Dashboard Live Video  </Typography>
      </Link>
      <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">
        <Typography variant="caption" color="textSecondary">║ Requêtes du serveur d&apos;alarme  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_and_SQL-Logging/">
        <Typography variant="caption" color="textSecondary">║ Node-RED et SQL  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_on_Android/">
        <Typography variant="caption" color="textSecondary">║ Node-RED sur Android  </Typography>
      </Link>
      <Link to="/Advanced_User/OpenHAB_Home_Automation/">
        <Typography variant="caption" color="textSecondary">║ OpenHAB  </Typography>
      </Link>
      <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/">
        <Typography variant="caption" color="textSecondary">║ OpenHAB IP Camera Binding  </Typography>
      </Link>
      <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/">
        <Typography variant="caption" color="textSecondary">║ openHABian  </Typography>
      </Link>
      <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/">
        <Typography variant="caption" color="textSecondary">║ IOBroker  </Typography>
      </Link>
      <Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/">
        <Typography variant="caption" color="textSecondary">║ Homematic CCU3 et RedMatic  </Typography>
      </Link>
      <Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/">
        <Typography variant="caption" color="textSecondary">║ Alexa et Node-RED  </Typography>
      </Link>
      <Link to="/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/">
        <Typography variant="caption" color="textSecondary">║ IFTTT et INSTAR FHD Cameras  </Typography>
      </Link>
      <Link to="/Advanced_User/IFTTT_as_Alarmserver/">
        <Typography variant="caption" color="textSecondary">║ IFTTT comme serveur d&apos;alarme  </Typography>
      </Link>
      <Link to="/Advanced_User/Node-RED_and_IFTTT/">
        <Typography variant="caption" color="textSecondary">║ Node-RED et IFTTT  </Typography>
      </Link>
      <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/">
        <Typography variant="caption" color="textSecondary">║ XiaoMi Home et Zigbee2MQTT  </Typography>
      </Link>
      <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/">
        <Typography variant="caption" color="textSecondary">║ ZoneMinder et Docker  </Typography>
      </Link>
      <Link to="/Advanced_User/Tasker/">
        <Typography variant="caption" color="textSecondary">║ Tasker sur Android  </Typography>
      </Link>
    </div>
  );
}
