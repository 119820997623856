import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function MotionGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Configurez votre caméra pour qu&apos;elle déclenche une alarme lorsqu&apos;un mouvement est détecté. Enregistrez des vidéos, informez-vous par e-mail ou par messages push et connectez votre caméra à votre environnement smarthome.  
      </Typography>
      <Link to="/Motion_Detection/Setup/">
        <Typography variant="caption" color="textSecondary">║ Configuration de la détection de mouvement  </Typography>
      </Link>
      <Link to="/Motion_Detection/Alarm_Notification/">
        <Typography variant="caption" color="textSecondary">║ Notification d&apos;alarme  </Typography>
      </Link>
      <Link to="/Motion_Detection/Alarm_Recording/">
        <Typography variant="caption" color="textSecondary">║ Enregistrement des alarmes  </Typography>
      </Link>
      <Link to="/Motion_Detection/SD_Card_Access/">
        <Typography variant="caption" color="textSecondary">║ Accès à la carte SD  </Typography>
      </Link>
      <Link to="/Motion_Detection/Alarm_FTP_Upload/">
        <Typography variant="caption" color="textSecondary">║ Alarme Téléchargement FTP  </Typography>
      </Link>
      <Link to="/Motion_Detection/Router_as_a_FTP_Server/">
        <Typography variant="caption" color="textSecondary">║ Routeur comme serveur FTP  </Typography>
      </Link>
      <Link to="/Motion_Detection/FTP_Server_Setup/">
        <Typography variant="caption" color="textSecondary">║ Configuration du serveur FTP  </Typography>
      </Link>
      <Link to="/Motion_Detection/Alarm_Server/">
        <Typography variant="caption" color="textSecondary">║ Serveur d&apos;alarme  </Typography>
      </Link>
      <Link to="/Motion_Detection/INSTAR_Cloud/">
        <Typography variant="caption" color="textSecondary">║ INSTAR Cloud</Typography>
      </Link>
    </div>
  );
}
