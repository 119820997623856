import React from 'react'
import { Link, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'

import SearchTable from '../components/Index/Frontpage/SearchContainer'
import InstallationGrid from '../components/Index/Frontpage/IntroInstallationGrid'
import MotionGrid from '../components/Index/Frontpage/IntroMotionGrid'
import RemoteGrid from '../components/Index/Frontpage/IntroRemoteGrid'
import DeveloperGrid from '../components/Index/Frontpage/IntroDeveloperGrid'
import ProductsGrid from '../components/Index/Frontpage/IntroProductsGrid'
import SoftwareGrid from '../components/Index/Frontpage/IntroSoftwareGrid'
import PopularGrid from '../components/Index/Frontpage/IntroPopularGrid'

import SEOHelmet from '../components/Layout/SEOHelmet'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    maxWidth: 425
  },
  image: {
    width: 50,
    height: 50,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  headmenu: {
    overflow: `auto`,
    whiteSpace: `nowrap`,
    marginBottom: 35
  },  
  menubutton: {
    display: `inline-block`,
    textAlign: `center`,
    padding: 14,
    textDecoration: `none`
  },
  menubuttonactive: {
    display: `inline-block`,
    textAlign: `center`,
    padding: `14px 14px 7px 14px`,
    textDecoration: `none`,
    borderBottom: `3px solid #2f78f9`,
    borderBottomWidth: 3,
    borderBottomStyle: `solid`,
    borderBottomColor: `rgb(47, 120, 249)`
  }
}))


const seodata = {
  title: 'INSTAR Wiki :: Base de connaissances pour les caméras IP et la surveillance des réseaux',
  description: 'INSTAR est l une des marques les plus connues de technologie de sécurité de haute qualité développée en Allemagne. INSTAR propose des caméras IP d intérieur/extérieur/des caméras de surveillance et offre également une plateforme unique de capture des nuages, y compris les fausses alarmes et la détection d objets. Toutes les caméras INSTAR sont & quot ; not & quot ; Cloud-bound et peuvent être intégrées facilement sans Internet dans le réseau local via LAN, WLAN ou PoE. Peu importe la caméra de surveillance INSTAR que vous utilisez, toutes les caméras sont compatibles avec des systèmes tiers tels que. Synology, QNAP, Asustor, Netgear, Luxone, KNX, Homeatic et bien d autres. En tant que fournisseur de caméras réseau WLAN, de caméras IP HD, de caméras IP contrôlables, de caméras IP avec vision nocturne infrarouge ainsi que d injecteurs PoE, d injecteurs Gigabit PoE, d adaptateurs CPL, de routeurs, de commutateurs, de détecteurs de mouvement avec PIR et micro-ondes, de microphones, d objectifs, d un chauffage breveté, INSTAR propose également des accessoires supplémentaires pour votre caméra de surveillance IP.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png'
}


export default function FrontGrid({data}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <SEOHelmet title={seodata.title} description={seodata.description} image={seodata.image} location="/fr/" />

      <div className={classes.headmenu}>
          <Link to="/" className={classes.menubuttonactive}>
            <Typography variant="button" color="textPrimary">Accueil</Typography>
          </Link>
          <Link to="/Latest/" className={classes.menubutton}>
            <Typography variant="button" color="textPrimary">Dernièr</Typography>
          </Link>
          <Link to="/Popular/" className={classes.menubutton}>
            <Typography variant="button" color="textPrimary">Populaire</Typography>
          </Link>
          <Link to="/Smarthome/" className={classes.menubutton}>
            <Typography variant="button" color="textPrimary">Smarthome</Typography>
          </Link>
          <Link to="/Search/" className={classes.menubutton}>
            <Typography variant="button" color="textPrimary">Recherchez</Typography>
          </Link>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary">Recherchez</Typography>
          <hr/>
          <Paper><SearchTable /></Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Link to="/Quick_Installation/">
            <Typography variant="h6" color="textSecondary">Installation rapide</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><InstallationGrid /></Paper>
          <Link to="/Motion_Detection/">
            <Typography variant="h6" color="textSecondary">Détection de mouvement</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><MotionGrid /></Paper>
          <Link to="/Internet_Access/">
            <Typography variant="h6" color="textSecondary">Accès à distance</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><RemoteGrid /></Paper>
          <Link to="/Products/">
            <Typography variant="h6" color="textSecondary">Des produits</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><ProductsGrid /></Paper>
          <Link to="/Software/">
            <Typography variant="h6" color="textSecondary">Software</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><SoftwareGrid /></Paper>
          <Link to="/Advanced_User/">
            <Typography variant="h6" color="textSecondary">Pour les développeurs</Typography>
          </Link>
          <hr/>
          <Paper className={classes.paper}><DeveloperGrid /></Paper>
          <Typography variant="h6" color="textSecondary">Articles populaires</Typography>
          <hr/>
          <Paper className={classes.paper}><PopularGrid /></Paper>
        </Grid>
        <Grid item xs={12} sm={6}>

            <Typography variant="h6" color="textSecondary">Accueil</Typography>
            <hr/>

            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <Paper className={classes.paper} key={index}>
                  <Grid container spacing={2}>
                      <Grid item>
                          <Link to={node.frontmatter.path} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                              <ButtonBase className={classes.image}>
                              <img className={classes.img} alt="complex" src={"/fr"+node.frontmatter.toc} />
                              </ButtonBase>
                          </Link>
                      </Grid>
                  <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Link to={node.frontmatter.path} style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                              <Typography variant="subtitle2" color="textSecondary">
                                  {node.frontmatter.title}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                  {node.frontmatter.dateChanged}
                              </Typography>
                            </Link>
                          </Grid>
                      </Grid>
                      <Grid item>
                      <Typography variant="caption">{node.timeToRead} Min</Typography>
                      </Grid>
                  </Grid>
                  </Grid>
              </Paper>
            ))}

        </Grid>
      </Grid>
    </div>
  );
}






export const FrontPageQuery = graphql`
  query FrontPageQuery {
    allMarkdownRemark(sort: {order: DESC, fields: frontmatter___dateChanged}, limit: 20) {
      edges {
        node {
          id
          frontmatter {
            dateChanged
            title
            path
            toc
            chapter
          }
          timeToRead
        }
      }
    }
  }
`