import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function InstallationGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Tout ce que vous devez savoir pour commencer à utiliser votre caméra IP INSTAR - connexion réseau et configuration de base de la caméra.  
      </Typography>
      <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
        <Typography variant="caption" color="textSecondary">║ Comment fonctionne une caméra IP ?  </Typography>
      </Link>
      <Link to="/Quick_Installation/After_Unpacking/">
        <Typography variant="caption" color="textSecondary">║ Après le déballage  </Typography>
      </Link>
      <Link to="/Quick_Installation/First_Steps/">
        <Typography variant="caption" color="textSecondary">║ Premiers pas  </Typography>
      </Link>
      <Link to="/Quick_Installation/Power_over_Ethernet/">
        <Typography variant="caption" color="textSecondary">║ Power over Ethernet  </Typography>
      </Link>
      <Link to="/Quick_Installation/Powerline/">
        <Typography variant="caption" color="textSecondary">║ Powerline  </Typography>
      </Link>
      <Link to="/Quick_Installation/Direct_LAN_Connection/">
        <Typography variant="caption" color="textSecondary">║ Connexion directe au réseau local  </Typography>
      </Link>
      <Link to="/Quick_Installation/Alternative_IP_Scanner/">
        <Typography variant="caption" color="textSecondary">║ Trouvez votre caméra  </Typography>
      </Link>
      <Link to="/Quick_Installation/Language_Selection/">
        <Typography variant="caption" color="textSecondary">║ Sélection de la langue  </Typography>
      </Link>
      <Link to="/Quick_Installation/Live_Video/">
        <Typography variant="caption" color="textSecondary">║ Vidéo en direct  </Typography>
      </Link>
      <Link to="/Quick_Installation/Creating_User_Accounts/">
        <Typography variant="caption" color="textSecondary">║ Gestion des utilisateurs  </Typography>
      </Link>
      <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
        <Typography variant="caption" color="textSecondary">║ Mise à jour du logiciel  </Typography>
      </Link>
      <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
        <Typography variant="caption" color="textSecondary">║ Naviguer dans l&apos;histoire  </Typography>
      </Link>
      <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
        <Typography variant="caption" color="textSecondary">║ Connexion sans fil  </Typography>
      </Link>
      <Link to="/Quick_Installation/ONVIF/">
        <Typography variant="caption" color="textSecondary">║ ONVIF</Typography>
      </Link>
    </div>
  );
}
