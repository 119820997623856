import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function RemoteGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Accédez à votre caméra via Internet - configuration de la connexion Point2Point et DNS dynamique.
      </Typography>
      <Link to="/Internet_Access/Point_to_Point/">
        <Typography variant="caption" color="textSecondary">║ P2P Point-2-Point  </Typography>
      </Link>
      <Link to="/Internet_Access/The_DDNS_Service/">
        <Typography variant="caption" color="textSecondary">║ INSTAR DDNS  </Typography>
      </Link>
      <Link to="/Internet_Access/Port_Forwarding/">
        <Typography variant="caption" color="textSecondary">║ Redirection portuaire  </Typography>
      </Link>
      <Link to="/Internet_Access/Mobile_Access/">
        <Typography variant="caption" color="textSecondary">║ Accès mobile  </Typography>
      </Link>
      <Link to="/Internet_Access/DDNS_Provider/">
        <Typography variant="caption" color="textSecondary">║ DDNS Fournirr</Typography>
      </Link>
    </div>
  );
}
