import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  intro: {
    marginBottom: 15,
  },
}));

export default function ProductsGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.intro}>
        Tous les produits INSTAR - documentation et téléchargements de logiciels.
      </Typography>
      <Link to="/Downloads/">
        <Typography variant="caption" color="textSecondary">║ Téléchargements  </Typography>
      </Link>
      <Link to="/Products/Usermanuals/">
        <Typography variant="caption" color="textSecondary">║ Manuels d&apos;utilisation  </Typography>
      </Link>
      <Link to="/Assistants/Review_Wall/">
        <Typography variant="caption" color="textSecondary">║ Témoignages  </Typography>
      </Link>
      <Link to="/Indoor_Cameras/">
        <Typography variant="caption" color="textSecondary">║ Caméras d&apos;intérieur  </Typography>
      </Link>
      <Link to="/Outdoor_Cameras/">
        <Typography variant="caption" color="textSecondary">║ Caméras d&apos;extérieur  </Typography>
      </Link>
      <Link to="/Products/Lenses/">
        <Typography variant="caption" color="textSecondary">║ Lentilles  </Typography>
      </Link>
      <Link to="/Web_User_Interface/1080p_Series/Overview/">
        <Typography variant="caption" color="textSecondary">║ Interface utilisateur Web  </Typography>
      </Link>
      <Link to="/Products/USB-Webcams/IN-W1/">
        <Typography variant="caption" color="textSecondary">║ Webcams USB  </Typography>
      </Link>
      <Link to="/Products/IN-LAN/">
        <Typography variant="caption" color="textSecondary">║ IN-LAN  </Typography>
      </Link>
      <Link to="/Products/Power_over_Ethernet/">
        <Typography variant="caption" color="textSecondary">║ Power over Ethernet  </Typography>
      </Link>
      <Link to="/Products/IN-Route/">
        <Typography variant="caption" color="textSecondary">║ IN-Route  </Typography>
      </Link>
    </div>
  );
}
